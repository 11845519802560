import React, {useState, useContext, useEffect, useRef} from "react";
import {withRouter} from "react-router";
import {
  sd_dashboard_dob,
  sd_dashboard_edit_ico,
  sd_trash,
  sd_failure_icon,
  sd_side_arrow,
  sd_success_icon,
} from "../../../../Assets/imageList";
import DatePicker from "react-date-picker";
import "react-toastify/dist/ReactToastify.css";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import moment from "moment";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import {SD_Context} from "../../../../context/sd-context";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import {toast} from "react-toastify";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import {
  DecryptInformation,
  EncryptInformation,
} from "../../../../Utils/sd-encrypt/encrypt";
import PhoneInput from "react-phone-input-2";
import {BASE_BANK} from "../../../../Utils/sd-axios/sd-api-url";
const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;

toast.configure();

const Sd_dashboard_online_booking_accommodation_confirm = ({history}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    currentBookingAccomadations,
    userData,
    devoteeList,
    logout,
  } = useContext(SD_Context);

  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [firstName, setFirstName] = useState("");
  const [docNumber, setdocNumber] = useState("");
  const [docType, setDockType] = useState("");
  const [LastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("91");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [uploadLink, setUploadLink] = useState("");
  const [charges, setCharges] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  const [email, setEmail] = useState("");
  const [result, setResult] = useState({});
  const [err, setErr] = useState([]);
  const [autoFill, setAutoFill] = useState("none");
  const ref = useRef(null);
  const [timestamp, setTimestamp] = useState("");
  // const [uploadLink2, setUploadLink2] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect-----------------------------------------------------------//
  useEffect(async () => {
    console.log(currentBookingAccomadations);
    setActive("online-booking-accommodation-confirm");
    setActivePath("Onlinebooking-Accommodations-Confirm");
    if (!currentBookingAccomadations) {
      history?.push(
        `/${selectedLanguage}/devotee-app/online-booking/accommodations`
      );
    }
    let res = await Sd_DashboardService.get_taxes(
      currentBookingAccomadations?.reserved?.price,
      userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
    );
    setCharges(res);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setTimestamp("");
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const handleFileUpload = async (e, serial) => {
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        // setUploadFlag(false);
        if (serial === "1") {
          document.getElementById(`uploadInputA`).value = "";
        } else if (serial === "2") {
          document.getElementById(`uploadInputB`).value = "";
        }
        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "accommodations"
      );
      toast.info("File Uploaded");

      if (serial === "1") {
        setUploadLink(res);
        if (err.find((e) => e.type === "upload")) {
          let data = err.filter((e) => e.type !== "upload");
          setErr(data);
        }
      } else if (serial === "2") {
        // setUploadLink2(res);
        // if (err.find((e) => e.type === "upload2")) {
        //   let data = err.filter((e) => e.type !== "upload2");
        //   setErr(data);
        // }
      }
    } catch (e) {
      toast.error(e.message);
      setUploadLink("");
      if (serial === "1") {
        document.getElementById(`uploadInputA`).value = "";
      } else if (serial === "2") {
        document.getElementById(`uploadInputB`).value = "";
      }

      // let upload = document.getElementById("upload-image-donation");
      // upload.files = [];
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations?state=clear`
        );
      }
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date?.getDate() + days);
    return copy;
  }

  const handleSubmit = async () => {
    setLoading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({type: "phone", msg: "Invalid mobile number"});
        }
      } else {
        if (mobile.length < 8) {
          data.push({type: "phone", msg: "Invalid mobile number"});
        }
      }
      if (email === "") {
        data.push({type: "email", msg: "Please enter a email id"});
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email)?.toLowerCase())) {
          data.push({type: "email", msg: "Please enter a email id"});
        }
      }
      if (firstName === "") {
        data.push({type: "fname", msg: "Please enter your first name"});
      } else if (firstName.length < 3) {
        data.push({type: "fname", msg: "Minimun 3 Characters"});
      }

      if (state === "") {
        data.push({type: "state", msg: "Please select your state"});
      }
      if (country === "") {
        data.push({type: "country", msg: "Please select your country"});
      }
      if (zip === "") {
        data.push({type: "zip", msg: "Invalid zipcode! Try again."});
      } else {
        if (zip.length !== 6) {
          data.push({type: "zip", msg: "Invalid zipcode! Try again."});
        }
      }
      if (docType === "") {
        data.push({type: "docType", msg: "Select the type of id proof"});
      } else {
        if (docType === "pan") {
          let txt = docNumber.toUpperCase();
          let regex =
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
          let pan = {
            C: "Company",
            P: "Personal",
            H: "Hindu Undivided Family (HUF)",
            F: "Firm",
            A: "Association of Persons (AOP)",
            T: "AOP (Trust)",
            B: "Body of Individuals (BOI)",
            L: "Local Authority",
            J: "Artificial Juridical Person",
            G: "Govt",
          };
          pan = pan[txt[3]];
          if (regex.test(txt)) {
            if (pan !== "Personal") {
              data.push({type: "docNumber", msg: "Invalid pan number"});
            }
          } else data.push({type: "docNumber", msg: "Invalid pan number"});
        } else if (docType === "ration") {
          let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({type: "docNumber", msg: "Invalid ration number"});
          }
        } else if (docType === "driving") {
          let regex =
            /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            // data.push({
            //   type: "docNumber",
            //   msg: "Invalid driving license number",
            // });
          }
        } else if (docType === "voter") {
          let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({type: "docNumber", msg: "Invalid voter id"});
          }
        } else if (docType === "aadhar") {
          let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({type: "docNumber", msg: "Invalid AADHAR number"});
          }
        }
        if (uploadLink === "") {
          data.push({type: "upload", msg: "Please attach a valid id proof"});
        }
      }
      // if (uploadLink2 === "") {
      //   data.push({
      //     type: "upload2",
      //     msg: "Please attach a valid COVID certificate",
      //   });
      // }
      if (city === "") {
        data.push({type: "city", msg: "Please enter a city"});
      }
      if (gender === "") {
        data.push({type: "gender", msg: "Please select your gender"});
      }
      if (dob === "") {
        data.push({type: "date", msg: "Please select a date"});
      }

      if (address1 === "") {
        data.push({type: "address1", msg: "Please enter a valid address"});
      }
      setErr(data);
      if (data.length !== 0) {
        setLoading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }

    let res;
    if (!halfState) {
      try {
        let data = {
          roomBookingDetails: {
            booking_date: moment(currentBookingAccomadations?.startDate).format(
              "YYYY-MM-DD"
            ),
            first_name: firstName,
            dob: moment(dob).format("YYYY-MM-DD"),
            address: address1,
            contact: `+${mobile}`,
            pin_code: zip,
            state: state,
            email: email,
            city: city,
            country: country,
            room_type: currentBookingAccomadations?.ac,
            gender: gender,
            idProofType: docType,
            idProofNumber: EncryptInformation(docNumber),
            doc_file_url: uploadLink.path ? uploadLink.path : uploadLink,
            no_of_persons: currentBookingAccomadations?.occupancy,
            // covid_certificate: uploadLink2.path
            //   ? uploadLink2.path
            //   : uploadLink2,
          },
          itemType: "accomodation",
          itemId: currentBookingAccomadations?.reserved?.id,
        };
        if (LastName !== "") {
          data.roomBookingDetails.last_name = LastName;
        }
        if (address2 !== "") {
          data.roomBookingDetails.address2 = address2;
        }
        let schema = [data];
        res = await ParokshaSevaService.bookings(schema, BASE_BANK, false);
        setHalfState(res);
        setResult(res);
      } catch (e) {
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
          );
        }
        setLoading(false);

        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${selectedLanguage}/devotee-app/online-booking/accommodations`
            );
          }, 3000);
          return;
        }
        await DisplayRazorpay(
          halfState?.paymentResp?.razorResp?.id,
          halfState?.paymentResp?.razorResp?.currency,
          halfState?.paymentResp?.razorResp?.amount.toString(),
          halfState?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");

        setLoading(false);
      } else {
        setTimestamp(Date.now());

        await DisplayRazorpay(
          res?.paymentResp?.razorResp?.id,
          res?.paymentResp?.razorResp?.currency,
          res?.paymentResp?.razorResp?.amount.toString(),
          res?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");

        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking ID
                <span className="mr-3">-</span>
                {result?.cart?.id} for accommodation at
                <span className="mr-3">
                  {currentBookingAccomadations?.reserved?.block}
                </span>{" "}
                has been made successfully. We look forward to hosting you.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${selectedLanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your booking could not be placed. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Your booking was not placed as there was a problem processing
                payement.<span>failed.</span>
                An error has occurred. Check your booking history or SMS after
                20 mins for confirmation. Please note that any funds debited
                will credit back to your account within 7 working days.
                {/* Please
                place your booking again, for any query click below */}
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };
  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };
  const autoFillFunction = (name) => {
    let array = [];
    let userClick = () => {
      // console.log(userData);
      userData?.first_name
        ? setFirstName(userData?.first_name)
        : setFirstName("");
      userData?.last_name ? setLastName(userData?.last_name) : setLastName("");
      userData?.gender ? setGender(userData?.gender) : setGender("");
      userData?.doc_file_url
        ? setUploadLink(userData?.doc_file_url)
        : setUploadLink("");
      userData?.id_proof_type
        ? setDockType(userData?.id_proof_type?.toLowerCase())
        : setDockType("");
      userData?.id_proof_number
        ? setdocNumber(DecryptInformation(userData?.id_proof_number))
        : setdocNumber("");
      userData?.city ? setCity(userData?.city) : setCity("");
      userData?.country ? setCountry(userData?.country) : setCountry("");
      userData?.address1 ? setAddress1(userData?.address1) : setAddress1("");
      userData?.address2 ? setAddress2(userData?.address2) : setAddress2("");
      userData?.state ? setState(userData?.state) : setState("");
      userData?.email ? setEmail(userData?.email) : setEmail("");
      userData?.phone
        ? setMobile(userData?.phone?.replace?.("+", ""))
        : setMobile("");
      userData?.pincode ? setZip(userData?.pincode) : setZip("");
      userData?.date_of_birth
        ? setDob(new Date(userData?.date_of_birth))
        : setDob("");
      let data = err.filter((e) => {
        if (
          e.type !== "fname" &&
          e.type !== "lname" &&
          e.type !== "gender" &&
          e.type !== "city" &&
          e.type !== "address1" &&
          e.type !== "state" &&
          e.type !== "country" &&
          e.type !== "mobile" &&
          e.type !== "zip" &&
          e.type !== "address2" &&
          e.type !== "email" &&
          e.type !== "upload" &&
          e.type !== "docNumber" &&
          e.type !== "docType" &&
          e.type !== "date"
        ) {
          return e;
        }
      });
      setErr(data);
      setAutoFill("none");
    };
    if (
      userData?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      array.push(
        <p
          className="sd-autofill-div"
          onClick={() => {
            userClick();
          }}
        >
          <p
            style={{textTransform: "capitalize"}}
            onClick={() => {
              userClick();
            }}
          >
            Name:{" "}
            {userData?.first_name
              ? userData?.first_name
              : userData?.display_name}
            <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
          </p>
          {userData?.date_of_birth && (
            <p
              onClick={() => {
                userClick();
              }}
            >
              Age:
              {fnCalculateAge(userData?.date_of_birth)} yrs
            </p>
          )}
          {userData?.gender && (
            <p
              onClick={() => {
                userClick();
              }}
            >
              Gender: {userData?.gender}
            </p>
          )}
        </p>
      );
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let onClicks = () => {
            // console.log(items);
            items?.first_name
              ? setFirstName(items?.first_name)
              : setFirstName("");
            items?.date_of_birth
              ? setDob(new Date(items?.date_of_birth))
              : setDob("");
            items?.last_name ? setLastName(items?.last_name) : setLastName("");
            items?.gender ? setGender(items?.gender) : setGender("");
            items?.doc_file_url
              ? setUploadLink(items?.doc_file_url)
              : setUploadLink("");
            items?.id_proof_type
              ? setDockType(items?.id_proof_type.toLowerCase())
              : setDockType("");
            items?.id_proof_number
              ? setdocNumber(EncryptInformation(items?.id_proof_number))
              : setdocNumber("");
            let data = err.filter((e) => {
              if (
                e.type !== "fname" &&
                e.type !== "lname" &&
                e.type !== "gender" &&
                e.type !== "upload" &&
                e.type !== "docNumber" &&
                e.type !== "docType" &&
                e.type !== "date"
              ) {
                return e;
              }
            });
            setErr(data);
            setAutoFill("none");
          };
          array.push(
            <p
              key={items.id}
              className="sd-autofill-div"
              onClick={() => {
                onClicks();
              }}
            >
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Name: {items?.first_name}
                <span style={{marginLeft: "3px"}}>{items?.last_name}</span>
              </p>
              {items?.dob && (
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
              )}
              {items?.gender && (
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Gender: {items?.gender}
                </p>
              )}
            </p>
          );
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "") {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userClick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userClick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return a;
      } else {
        // console.log("none");
        // setAutoFill("none");
        return "";
      }
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400" ref={ref}>
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="40%">Booking for</th>
                  <th width="20%">Date / Time</th>
                  <th width="20%">Particulars</th>
                  <th width="20%">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Accommodation
                    <br></br>(
                    {currentBookingAccomadations?.reserved?.room_type ===
                    "NONAC"
                      ? "Non A/C"
                      : currentBookingAccomadations?.reserved?.room_type ===
                        "AC"
                      ? "AC"
                      : currentBookingAccomadations?.reserved?.room_type ===
                        "SUITE"
                      ? "Suite"
                      : "COTTAGE"}
                    )
                  </td>
                  <td>
                    Check In<br></br>
                    8AM-
                    {moment(currentBookingAccomadations?.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  <td>
                    {currentBookingAccomadations?.reserved?.room_type ===
                    "NONAC"
                      ? "Non A/C"
                      : currentBookingAccomadations?.reserved?.room_type ===
                        "AC"
                      ? "AC"
                      : currentBookingAccomadations?.reserved?.room_type ===
                        "SUITE"
                      ? "Suite"
                      : "COTTAGE"}
                    <br></br>({currentBookingAccomadations?.occupancy} Persons)
                  </td>
                  {(() => {
                    if (currentBookingAccomadations?.reserved?.price > 0) {
                      let percentage = 0;
                      if (
                        currentBookingAccomadations?.reserved?.price > 0 &&
                        currentBookingAccomadations?.reserved?.price < 7501
                      ) {
                        percentage =
                          (currentBookingAccomadations?.reserved?.price / 100) *
                          12;
                      } else if (
                        currentBookingAccomadations?.reserved?.price > 7500
                      ) {
                        percentage =
                          (currentBookingAccomadations?.reserved?.price / 100) *
                          18;
                      }

                      return (
                        <td>
                          {" "}
                          Rs.{" "}
                          {Number(
                            Number(charges?.totalAmount) + Number(percentage)
                          ).toFixed(2)}{" "}
                          <br /> (Handling charges:{" "}
                          {Number(charges?.additionalAmount).toFixed(2)})
                          <br /> GST Charges: Rs.{Number(percentage).toFixed(2)}
                        </td>
                      );
                    } else {
                      return (
                        <td>
                          {" "}
                          Rs. {charges?.totalAmount}.00 <br /> (Handling
                          charges: {~~charges?.additionalAmount}.00)
                        </td>
                      );
                    }
                  })()}
                </tr>

                <tr>
                  <td>
                    <span
                      style={{
                        color: "grey",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Block Name
                    </span>{" "}
                    <br></br>
                    {currentBookingAccomadations?.reserved?.block}
                  </td>
                  <td>
                    Check Out<br></br>
                    8AM-
                    {moment(
                      addDays(currentBookingAccomadations?.startDate, 1)
                    ).format("DD-MM-YYYY")}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  <label style={{color: "grey", fontWeight: 500}}>
                    BOOKING FOR
                  </label>
                  <br />
                  <span className="fw500">
                    <span
                      style={{textTransform: "capitalize", fontWeight: "600"}}
                    >
                      {" "}
                      {currentBookingAccomadations?.reserved?.block}
                    </span>{" "}
                    <br></br>Room Type: (
                    <span
                      style={{textTransform: "capitalize", fontWeight: "600"}}
                    >
                      {currentBookingAccomadations?.reserved?.room_type ===
                      "NONAC"
                        ? "Non A/C"
                        : currentBookingAccomadations?.reserved?.room_type ===
                          "AC"
                        ? "AC"
                        : currentBookingAccomadations?.reserved?.room_type ===
                          "SUITE"
                        ? "Suite"
                        : currentBookingAccomadations?.reserved?.room_type ===
                          "COTTAGE"
                        ? "COTTAGE"
                        : ""}
                    </span>
                    ) - Persons - (
                    <span
                      style={{textTransform: "capitalize", fontWeight: "600"}}
                    >
                      {currentBookingAccomadations?.occupancy}
                    </span>
                    )
                  </span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  Check In<br></br>
                  <span>
                    {moment(currentBookingAccomadations?.startDate).format(
                      "Do MMMM YYYY"
                    )}
                  </span>
                </div>
                <div style={{marginRight: "0px", marginTop: "5px"}}>
                  <br />
                  Checkout <br />
                  <span>
                    {" "}
                    {moment(
                      addDays(currentBookingAccomadations?.startDate, 1)
                    ).format("Do MMMM YYYY")}
                  </span>
                </div>
              </div>

              <div className="sd-booking-details-down ">
                <div>
                  <label>AMOUNT</label>
                  <span>
                    {(() => {
                      if (currentBookingAccomadations?.reserved?.price > 0) {
                        let percentage = 0;

                        if (
                          currentBookingAccomadations?.reserved?.price > 0 &&
                          currentBookingAccomadations?.reserved?.price < 7501
                        ) {
                          percentage =
                            (currentBookingAccomadations?.reserved?.price /
                              100) *
                            12;
                        } else if (
                          currentBookingAccomadations?.reserved?.price > 7500
                        ) {
                          percentage =
                            (currentBookingAccomadations?.reserved?.price /
                              100) *
                            18;
                        }
                        return (
                          <>
                            {" "}
                            Rs.{" "}
                            {Number(
                              Number(charges?.totalAmount) + Number(percentage)
                            ).toFixed(2)}{" "}
                            <br /> (Handling charges:{" "}
                            {Number(charges?.additionalAmount).toFixed(2)})
                            <br /> GST Charges: Rs.
                            {Number(percentage).toFixed(2)}
                          </>
                        );
                      } else {
                        return (
                          <>
                            {" "}
                            Rs. {charges?.totalAmount}.00 <br /> (Handling
                            charges: {~~charges?.additionalAmount}.00)
                          </>
                        );
                      }
                    })()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <h3
            className="sd-side-heading fw400 mt-30"
            style={{paddingBottom: "10px"}}
          >
            <span className="fw400">User Details</span>
            {/* <div
              className="sd-confirm-edit-autofill "
              onClick={() => {
                setAutoFill(!autoFill);
              }}
            >
             
            </div> */}
          </h3>
          <hr className="clearfix"></hr>
          <div className="clearfix">
            <form className="sd-form">
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    First Name<span className="sd-imp">*</span>
                  </label>
                  <input
                    autoFocus
                    maxLength="30"
                    type="text"
                    className={
                      err.find((e) => e.type === "fname")
                        ? "form-control sd-red"
                        : firstName !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    onFocus={() => {
                      if (autoFill === `autofill`) {
                        setAutoFill("none");
                      } else {
                        setAutoFill(`autofill`);
                      }
                    }}
                    onBlur={() => {
                      if (autoFill === `autofill`) {
                        setTimeout(() => {
                          setAutoFill("none");
                        }, 250);
                      }
                    }}
                    placeholder="Please enter your first name"
                    value={firstName}
                    autoComplete="new-password"
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "fname")) {
                          let data = err.filter((e) => e.type !== "fname");
                          setErr(data);
                        }
                        setFirstName(e.target.value);
                      }
                    }}
                  />
                  {autoFill === `autofill` && autoFillFunction(firstName)}
                  <p className="sd-EM mr-30 ">
                    {err.find((e) => e.type === "fname")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>Last Name</label>
                  <input
                    maxLength="30"
                    type="text"
                    className={
                      err.find((e) => e.type === "fname")
                        ? "form-control sd-red"
                        : LastName !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Please enter your last name"
                    value={LastName}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "lname")) {
                          let data = err.filter((e) => e.type !== "lname");
                          setErr(data);
                        }
                        setLastName(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM">
                    {err.find((e) => e.type === "lname")?.msg}
                  </p>
                </div>
              </div>

              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    Mobile Number<span className="sd-imp">*</span>
                  </label>

                  <div className={"sd-profile-mobile"}>
                    <PhoneInput
                      inputExtraProps={{
                        autoFocus: true,
                        enableSearch: true,
                        countryCodeEditable: false,
                      }}
                      onlyCountries={["in"]}
                      // onlyCountries={[
                      //   "in",
                      //   "au",
                      //   "ca",
                      //   "sg",
                      //   "ch",
                      //   "gb",
                      //   "us",
                      // ]}
                      placeholder="Enter Your 10 digit mobile number"
                      maxLength="15"
                      countryCodeEditable={false}
                      containerClass={
                        err?.find((e) => e.type === "phone")
                          ? "sd-phone-voilet sd-red"
                          : mobile !== ""
                          ? "sd-phone-voilet"
                          : ""
                      }
                      country="in"
                      value={mobile}
                      onChange={(e) => {
                        if (
                          err?.find(
                            (e) => e.type === "phone" || e.type === "common"
                          )
                        ) {
                          let array = err.filter((e) => {
                            if (e.type !== "phone" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(array);
                        }
                        setMobile(e);
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "mobile")?.msg}
                    </p>
                  </div>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pr-0">
                  <label>
                    Email address <span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "email")
                        ? "form-control sd-red"
                        : email !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Please enter a valid email id"
                    value={email}
                    style={{textTransform: "none"}}
                    onChange={(e) => {
                      if (err.find((e) => e.type === "email")) {
                        let data = err.filter((e) => e.type !== "email");
                        setErr(data);
                      }
                      setEmail(e.target.value);
                    }}
                  />
                  <p className="sd-EM  ">
                    {err.find((e) => e.type === "email")?.msg}
                  </p>
                </div>
              </div>

              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pl-0">
                  <label>
                    Date of Birth<span className="sd-imp">*</span>
                  </label>
                  <div
                    className={
                      err.find((e) => e.type === "date")
                        ? "sd-dob customDatePickerWidth sd-red"
                        : dob !== ""
                        ? "sd-dob customDatePickerWidth sd-voilet"
                        : "sd-dob customDatePickerWidth"
                    }
                  >
                    <DatePicker
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      style={{
                        width: "90%",
                        border: "none",
                        backgroundColor: "blue",
                      }}
                      locale="as-IN"
                      format="dd-MM-y"
                      maxDate={addDays(new Date(), -6570)}
                      calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                      clearIcon={null}
                      value={dob}
                      onChange={(date) => {
                        if (err.find((e) => e.type === "date")) {
                          let data = err.filter((e) => e.type !== "date");
                          setErr(data);
                        }
                        // console.log(date);
                        setDob(date);
                      }}
                    />
                  </div>
                  <p className="sd-EM ">
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>
                    Gender<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err.find((e) => e.type === "gender")
                        ? "form-control sd-red"
                        : gender !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={gender}
                    onChange={(e) => {
                      if (err.find((e) => e.type === "gender")) {
                        let data = err.filter((e) => e.type !== "gender");
                        setErr(data);
                      }
                      setGender(e.target.value);
                    }}
                  >
                    <option key={""} value="" style={{display: "none"}}>
                      Select a gender
                    </option>
                    <option key={"male"} value="male">
                      Male
                    </option>
                    <option key="female" value="female">
                      Female
                    </option>
                    <option key="others" value="others">
                      Others
                    </option>
                  </select>
                  <p className="sd-EM ">
                    {err.find((e) => e.type === "gender")?.msg}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>

              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    Address Line 1<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "address1")
                        ? "form-control sd-red"
                        : address1 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={address1}
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "address1")) {
                          let data = err.filter((e) => e.type !== "address1");
                          setErr(data);
                        }
                        setAddress1(e.target.value);
                      }
                    }}
                    placeholder="House/building number, street number
                    "
                  />
                  <p className="sd-EM mr-30">
                    {err.find((e) => e.type === "address1")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      address2 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Building name, street name, etc"
                    value={address2}
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setAddress2(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    City<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "city")
                        ? "form-control sd-red"
                        : city !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={city}
                    placeholder="Please enter your city"
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "city")) {
                          let data = err.filter((e) => e.type !== "city");
                          setErr(data);
                        }
                        setCity(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-30">
                    {err.find((e) => e.type === "city")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>
                    ZIP/Pincode<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    className={
                      err.find((e) => e.type === "zip")
                        ? "form-control sd-red"
                        : zip !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Enter your zipcode"
                    value={zip}
                    onChange={(e) => {
                      if (err.find((e) => e.type === "zip")) {
                        let data = err.filter((e) => e.type !== "zip");
                        setErr(data);
                      }
                      if (e.target.value.length <= 6) {
                        setZip(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM ">
                    {err.find((e) => e.type === "zip")?.msg}
                  </p>
                </div>
              </div>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 ">
                  <label>
                    State<span className="sd-imp">*</span>
                  </label>
                  <RegionDropdown
                    country={country}
                    classes={
                      err.find((e) => e.type === "state")
                        ? "form-control sd-border-none w-100 sd-red"
                        : state !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    value={state}
                    showDefaultOption={true}
                    blankOptionLabel="Select your State"
                    onChange={(val) => {
                      if (err.find((e) => e.type === "state")) {
                        let data = err.filter((e) => e.type !== "state");
                        setErr(data);
                      }
                      setState(val);
                    }}
                  />
                  <p className="sd-EM">
                    {err.find((e) => e.type === "state")?.msg}
                  </p>
                  {/* <select className="form-control" onChange={(e) => {}}>
                  <option>Telangana</option>
                  <option>Andhra Pradesh</option>
                </select> */}
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>
                    Country<span className="sd-imp">*</span>
                  </label>
                  <CountryDropdown
                    value={country}
                    classes={
                      err.find((e) => e.type === "country")
                        ? "form-control sd-border-none w-100 sd-red"
                        : country !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    showDefaultOption={true}
                    defaultOptionLabel="Select your Country"
                    onChange={(val) => {
                      if (err.find((e) => e.type === "country")) {
                        let data = err.filter((e) => e.type !== "country");
                        setErr(data);
                      }
                      setCountry(val);
                    }}
                  />
                  <p className="sd-EM mr-30">
                    {err.find((e) => e.type === "country")?.msg}
                  </p>
                </div>
              </div>

              <hr className="clearfix"></hr>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 pl-0 xssm-pl-0 xssm-pr-0">
                  <label>
                    ID Proof Type<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err?.find((e) => e.type === `docType`)
                        ? "form-control sd-red"
                        : docType !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    onChange={(e) => {
                      if (err.find((e) => e.type === "docType")) {
                        let data = err.filter((e) => e.type !== "docType");
                        setErr(data);
                      }
                      let value = docType;
                      setDockType(e.target.value);
                      if (value !== e.target.value) {
                        setdocNumber("");
                        setUploadLink("");
                      }
                    }}
                    value={docType}
                  >
                    <option style={{display: "none"}} value="" key="">
                      Select a ID proof
                    </option>
                    <option key="pan" value="pan">
                      PAN
                    </option>
                    <option key="aadhar" value="aadhar">
                      AADHAR
                    </option>
                    <option key="driving" value="driving">
                      Driving License
                    </option>
                    <option key="ration" value="ration">
                      Ration Card
                    </option>
                    <option key="voter" value="voter">
                      Voter ID
                    </option>
                  </select>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "docType")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 sd-profile-mobile col-sm-12 col-md-6 col-lg-6  xssm-pr-0 ">
                  <label>
                    Photo ID Number<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Please enter your id number"
                    className={
                      err.find((e) => e.type === "docNumber")
                        ? "form-control sd-red"
                        : docNumber !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    style={{paddingLeft: "15px", textTransform: "none"}}
                    value={docNumber}
                    onChange={(e) => {
                      if (
                        /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "docNumber")) {
                          let data = err.filter((e) => {
                            if (e.type !== "docNumber" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setdocNumber(
                          e.target?.value?.toUpperCase()?.replaceAll?.(" ", "")
                        );
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "docNumber")?.msg}
                  </p>
                </div>
                <div
                  className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  pl-0 mb-0"
                  style={{marginBottom: "0px !important"}}
                >
                  <label>
                    Upload ID Proof<span className="sd-imp">*</span>
                  </label>
                  {uploadLink !== "" ? (
                    <div className="upload-button">
                      <p>
                        {" "}
                        {uploadLink?.originalname
                          ? uploadLink?.originalname
                          : "File uploaded"}{" "}
                      </p>{" "}
                      <img
                        src={sd_trash}
                        alt="delete"
                        onClick={() => {
                          setUploadLink("");
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {uploadLink === "" && (
                    <input
                      style={{padding: "10px 12px"}}
                      type="file"
                      placeholder="Browse"
                      id="uploadInputA"
                      accept="image/png, image/jpeg,image/jpg,.pdf"
                      className={
                        err.find((e) => e.type === "upload")
                          ? "form-control sd-red"
                          : "form-control"
                      }
                      onChange={(e) => {
                        if (err.find((e) => e.type === "upload")) {
                          let data = err.filter((e) => e.type !== "upload");
                          setErr(data);
                        }
                        handleFileUpload(e, "1");
                      }}
                    />
                  )}
                  <p className="sd-EM">
                    {err.find((e) => e.type === "upload")?.msg}
                  </p>
                </div>
                {/* <div
                  className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  pr-0 mb-0"
                  style={{marginBottom: "0px !important"}}
                >
                  <label>
                    Upload COVID Certificate<span className="sd-imp">*</span>
                  </label>
                  {uploadLink2 !== "" ? (
                    <div className="upload-button">
                      <p>
                        {" "}
                        {uploadLink2?.originalname
                          ? uploadLink2?.originalname
                          : "File uploaded"}{" "}
                      </p>{" "}
                      <img
                        src={sd_trash}
                        alt="delete"
                        onClick={() => {
                          setUploadLink2("");
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {uploadLink2 === "" && (
                    <input
                      style={{padding: "10px 12px"}}
                      type="file"
                      placeholder="Browse"
                      id="uploadInputB"
                      accept="image/png, image/jpeg,image/jpg,.pdf"
                      className={
                        err.find((e) => e.type === "upload2")
                          ? "form-control sd-red"
                          : "form-control"
                      }
                      onChange={(e) => {
                        if (err.find((e) => e.type === "upload2")) {
                          let data = err.filter((e) => e.type !== "upload2");
                          setErr(data);
                        }
                        handleFileUpload(e, "2");
                      }}
                    />
                  )}
                  <p className="sd-EM">
                    {err.find((e) => e.type === "upload2")?.msg}
                  </p>
                </div> */}
              </div>
              <hr className="clearfix"></hr>
            </form>
          </div>
          <div className="clearfix mt-30 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_accommodation_confirm);
